
<template>
<div>
  <div v-if="!isLoading">
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-4">Orders</h5>
        <div class="row g-4 mb-3">
          <div class="col-sm">
            <div class="d-flex justify-content-between gap-2">
              <div>
                <select v-model="defaultStatus" class="form-select" aria-label="Order Status">
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="delivered">Delivered</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
              <div class="search-box">
                <input type="text" v-model="searchQuery" class="form-control" placeholder="Search..." />
                <i class="ri-search-line search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orders.length" class="row gy-2">
      <div class="col-xxl-3 col-sm-6 project-card"
          v-for="(order, oIndex) of searchedOrders" :key="oIndex">
        <order-card :order="order"/>
      </div>
    </div>
    <div v-else class="fs-5 py-4 text-muted text-center">You don't have any order in your account. </div>
  </div>
  <div v-else class="row g-4 py-4 justify-content-center">
    <div class="col-12">
      <is-loading />
    </div>
  </div>
</div>
</template>

<script>

import OrderCard from "@/components/cards/OrderCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{
    OrderCard,
    IsLoading
  },
  data(){
    return {
      isLoading: true,
      defaultStatus:"all",
      searchQuery:"",
    }
  },
  computed:{
    orders(){
      return this.defaultStatus == "all"
              ? this.$store.state.orderList.orders
              : this.$store.state.orderList.orders.filter(ord => ord.status == this.defaultStatus )
    },
    searchedOrders(){
      return this.orders.filter(order => order.number ? order.number.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
  },
  created(){
    this.$store.dispatch("orderList/fetchOrders")
    .then((response) => { this.isLoading = false; });
  },
}

</script>

<style lang="scss">
</style>
